// modules
import React, { useEffect } from "react";
import "../styles/HelpPage.scss";


import { fetchAuthSession , } from 'aws-amplify/auth';
const HelpPage = () => {
  
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const session = await fetchAuthSession();
       // const idToken = session.tokens.idToken.toString()
         console.log(session) 
        



    
      } catch (err) {
          console.error('Error fetching data:', err);
  
      }
  };

  fetchData();
  }, []);

  
    
  
    


  
  return (
    <div className="Container">
      <h1 className="Greeting">Help Page</h1>
      
      
    </div>
  );
};

export default HelpPage;